<template>
	<layout :menu-tree="menuTree"></layout>
</template>

<script>
	import Layout from "@/views/front/jsf35/operations";
	import Embed from "@/views/panel-group/components/embed/View";
	import { reactive, toRefs } from "@vue/composition-api";

	export default {
		name: "OperationsCenter",
		components: {
			Layout,
			Embed,
		},
		setup(props) {
			const event = reactive({
				// 设备列表
				menuTree: [
					{
						title: "用户管理",
						children: [
							{
								title: "用户列表",
								iframe: "device.360sheji.cn/ems/admin/index",
							},
							{
								title: "部门列表",
								iframe: "device.360sheji.cn/ems/department/index",
							},
							{
								title: "职位列表",
								iframe: "device.360sheji.cn/ems/position/index",
							},
							{
								title: "角色列表",
								iframe: "device.360sheji.cn/ems/role/index",
							},
						],
					},
					{
						title: "资讯管理",
						children: [
							{
								title: "APP",
								children: [
									{
										title: "公告列表",
										iframe: "device.360sheji.cn/ems/notice/index",
									},
									{
										title: "关于我们",
										iframe: "device.360sheji.cn/ems/abouts/index",
									},
								],
							},
							{
								title: "小程序",
								children: [
									{
										title: "公告发布",
										iframe: "v4.qiyebox.com/#/m/news/announcement",
									},
									{
										title: "资讯管理",
										iframe: "v4.qiyebox.com/#/m/news/list",
									},
									{
										title: "活动列表",
										iframe: "v4.qiyebox.com/#/m/activity/views/activity_manage/list",
									},
									{
										title: "活动订单",
										iframe: "v4.qiyebox.com/#/m/activity/order/list",
									},
								],
							},
						],
					},

					
					{
						title: "招商租赁管理",
						children: [
							{
								title: "楼书管理",
								children: [
									{
										title: "收入预测",
										iframe: "v4.qiyebox.com/#/m/project/revenue",
									},
									{
										title: "项目管理",
										iframe: "v4.qiyebox.com/#/m/project/list",
									},
									{
										title: "楼栋管理",
										iframe: "v4.qiyebox.com/#/m/project/building",
									},
									{
										title: "房间管理",
										iframe: "v4.qiyebox.com/#/m/project/room",
									},
									{
										title: "企业管理",
										iframe: "v4.qiyebox.com/#/m/project/client",
									},
									{
										title: "企业审核",
										iframe: "v4.qiyebox.com/#/m/project/client/audit",
									},
									{
										title: "企业信息列表",
										iframe: "v4.qiyebox.com/#/m/project/custom/list",
									},
									{
										title: "行业管理",
										iframe: "v4.qiyebox.com/#/m/project/industry",
									},
								],
							},
							{
								title: "租赁管理",
								children: [
									{
										title: "新签合同",
										iframe: "v4.qiyebox.com/#/m/lease/contract",
									},
									{
										title: "合同变更列表",
										iframe: "v4.qiyebox.com/#/m/lease/contract_change/list",
									},
									{
										title: "合同签订",
										iframe: "v4.qiyebox.com/#/m/lease/contract2",
									},
									{
										title: "合同列表",
										iframe: "v4.qiyebox.com/#/m/lease/contract/list",
									},
									{
										title: "入住办理",
										iframe: "v4.qiyebox.com/#/m/lease/check",
									},
									{
										title: "退租办理",
										iframe: "v4.qiyebox.com/#/m/lease/surrender",
									},
								],
							},
							{
								title: "财账管理",
								children: [
									{
										title: "费项管理",
										iframe: "v4.qiyebox.com/#/m/bill/cost",
									},
									{
										title: "账单管理",
										iframe: "v4.qiyebox.com/#/m/bill/list",
									},
									{
										title: "结算统计",
										iframe: "v4.qiyebox.com/#/m/bill/settlement",
									},
									{
										title: "收支管理",
										iframe: "v4.qiyebox.com/#/m/bill/payment",
									},
									{
										title: "收入汇总",
										iframe: "v4.qiyebox.com/#/m/bill/income_summary/list",
									},
									{
										title: "营业额管理",
										iframe: "v4.qiyebox.com/#/m/bill/turnover",
									},
									{
										title: "营业抽成",
										iframe: "v4.qiyebox.com/#/m/bill/businessProfit",
									},
									{
										title: "价格策略",
										iframe: "v4.qiyebox.com/#/m/bill//priceStrategy/list",
									},
									{
										title: "开票记录",
										iframe: "v4.qiyebox.com/#/m/bill/invoice",
									},
								],
							},
							{
								title: "招商管理",
								children: [
									{
										title: "意向列表",
										iframe: "v4.qiyebox.com/#/m/investment/list",
									},
								],
							},
							{
								title: "数据分析",
								children: [
									{
										title: "资产地图",
										iframe: "v4.qiyebox.com/#/m/analysis/property/map",
									},
									{
										title: "租控图",
										iframe: "v4.qiyebox.com/#/m/analysis/building",
									},
									{
										title: "租赁分析",
										iframe: "v4.qiyebox.com/#/m/analysis/lease",
									},
								],
							},
						],
					},
					{
						title: "企业服务",
						children: [
							{
								title: "问卷调查",
								iframe: "v4.qiyebox.com/#/m/enterprise/question_investigation/list",
							},
							{
								title: "服务商管理",
								iframe: "v4.qiyebox.com/#/m/enterprise/brand",
							},
							{
								title: "服务类目",
								iframe: "v4.qiyebox.com/#/m/enterprise/list",
							},
							{
								title: "积分设置",
								iframe: "v4.qiyebox.com/#/m/enterprise/Integral/list",
							},
							{
								title: "积分明细",
								iframe: "v4.qiyebox.com/#/m/enterprise/integral/record",
							},
							{
								title: "信息上报",
								iframe: "v4.qiyebox.com/#/m/enterprise/information_reported/list",
							},
						],
					},
					{
						title: "基础设置",
						children: [
							{
								title: "甲方设置",
								iframe: "v4.qiyebox.com/#/m/setting/client",
							},
							{
								title: "系统设置",
								iframe: "v4.qiyebox.com/#/m/setting/system",
							},
							{
								title: "合同模板列表",
								iframe: "v4.qiyebox.com/#/m/setting/contract/template",
							},
							{
								title: "字典管理",
								iframe: "v4.qiyebox.com/#/m/setting/dict",
							},
							{
								title: "首页图表设置",
								iframe: "v4.qiyebox.com/#/m/setting/chart/home",
							},
							{
								title: "操作日志",
								iframe: "v4.qiyebox.com/#/m/setting/journal/list",
							},
							{
								title: "自定义模板列表",
								iframe: "v4.qiyebox.com/#/m/setting/custom/list",
							},
						],
					},
				],
			});

			return {
				...toRefs(event),
			};
		},
	};
</script>

<style lang="scss" scoped></style>
